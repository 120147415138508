const authMethods = {
    id: "auth_methods",
    data: [
        {
            id: "3ds",
            label: "ref.transaction.auth_methods.3ds",
            label2: "ref.transaction.auth_methods.3ds_newOldValue",
        },
        {
            id: "APPLE-PAY",
            label: "ref.transaction.auth_methods.APPLE-PAY",
        },
    ],
    translate: true,
};

export default authMethods;
