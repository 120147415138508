/**
 * Display Types
 * Définis le type de rendu associé aux champs,
 * formats de retour de l"API attendu,
 * format de cellule des tableaux...
 * @type {string}
 */
export const DISPLAY_ACCOUNT = "account";
export const DISPLAY_ACCOUNT_NUMBER = "account_number";
export const DISPLAY_ADDRESS = "address";
export const DISPLAY_AMOUNT = "amount";
export const DISPLAY_AUTH_STATUS = "auth_status";
export const DISPLAY_AVATAR = "avatar";
export const DISPLAY_CHECKBOX = "checkbox";
export const DISPLAY_COUNTRY = "country";
export const DISPLAY_CUSTOM_DATA = "custom_data";
export const DISPLAY_DATE = "date";
export const DISPLAY_EXPANDABLE = "expandable";
export const DISPLAY_ICON = "icon";
export const DISPLAY_IMAGE = "image";
export const DISPLAY_LINK = "link";
export const DISPLAY_MAP = "map";
export const DISPLAY_MONTH = "month";
export const DISPLAY_NUMERIC = "numeric";
export const DISPLAY_CHIP = "chip";
export const DISPLAY_RATE = "rate";
export const DISPLAY_SENTINEL = "sentinel";
export const DISPLAY_3DS = "3ds";
export const DISPLAY_SCORE = "score";
export const DISPLAY_STATUS = "status";
export const DISPLAY_TEXT = "text";
export const DISPLAY_LONG_TEXT = "long_text";
export const DISPLAY_THIRD_PARTY_SECURITY = "third_party_security";
export const DISPLAY_BOOLEAN = "boolean";
export const DISPLAY_MULTIPLE_LINKS = "multiple_links";
export const DISPLAY_STRINGIFY_OBJECT = "stringify_object";

export const displayTypes = {
    DISPLAY_ACCOUNT: "account",
    DISPLAY_ACCOUNT_NUMBER: "account_number",
    DISPLAY_ADDRESS: "address",
    DISPLAY_AMOUNT: "amount",
    DISPLAY_AUTH_STATUS: "auth_status",
    DISPLAY_AVATAR: "avatar",
    DISPLAY_CHECKBOX: "checkbox",
    DISPLAY_COUNTRY: "country",
    DISPLAY_CUSTOM_DATA: "custom_data",
    DISPLAY_DATE: "date",
    DISPLAY_EXPANDABLE: "expandable",
    DISPLAY_ICON: "icon",
    DISPLAY_IMAGE: "image",
    DISPLAY_LINK: "link",
    DISPLAY_MONTH: "month",
    DISPLAY_NUMERIC: "numeric",
    DISPLAY_CHIP: "chip",
    DISPLAY_RATE: "rate",
    DISPLAY_SENTINEL: "sentinel",
    DISPLAY_SCORE: "score",
    DISPLAY_STATUS: "status",
    DISPLAY_TEXT: "text",
    DISPLAY_3DS: "3ds",
    DISPLAY_LONG_TEXT: "long_text",
    DISPLAY_THIRD_PARTY_SECURITY: "third_party_security",
    DISPLAY_BOOLEAN: "boolean",
    DISPLAY_MULTIPLE_LINKS: "multiple_links",
    DISPLAY_STRINGIFY_OBJECT: "stringify_object",
};
