import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import { formatRate } from "@hipay/hipay-material-ui/utils/helpers";
import {
    DISPLAY_ADDRESS,
    DISPLAY_AMOUNT,
    DISPLAY_DATE,
    DISPLAY_ICON,
    DISPLAY_IMAGE,
    DISPLAY_NUMERIC,
    DISPLAY_RATE,
    DISPLAY_SENTINEL,
    DISPLAY_3DS,
    DISPLAY_SCORE,
    DISPLAY_TEXT,
    DISPLAY_LONG_TEXT,
    DISPLAY_CUSTOM_DATA,
    DISPLAY_AVATAR,
    DISPLAY_LINK,
    DISPLAY_MONTH,
    DISPLAY_BOOLEAN,
    DISPLAY_CHIP,
    DISPLAY_MULTIPLE_LINKS,
    DISPLAY_STRINGIFY_OBJECT,
} from "../../constants/displayTypes";
import {
    CellAddress,
    CellDate,
    CellEmpty,
    CellIconStyled,
    CellImageStyled,
    CellNumeric,
    CellRate,
    CellSentinel,
    CellScore,
    CellTextStyled,
    CellAvatar,
    CellButton,
    CellLink,
    CellMonth,
    CellBoolean,
    CellMultipleLinks,
    CellChip,
    CellStringifyObject,
    Cell3ds,
} from "./Cell";
import { useP } from "../../../services/i18n";
import { useCan } from "../../../services/ability";
import { useNoticeContextMenu } from "../../../services/ContextMenu";
import { toUserLocalNumber } from "../../../utils/i18n";
import { VIEWS } from "../../../constants";
import makeStyles from "@mui/styles/makeStyles";

const GDPR_ENCRYPT_VALUE = "******";

const useStylesCellBuilder = makeStyles((theme) => ({
    booleanCell: {
        height: "inherit",
        justifyContent: "left",
    },
}));

function CellBuilder(props) {
    const {
        attribute,
        onClick,
        color,
        composedFields,
        data,
        dataEntity,
        displayCurrency,
        displayTime,
        displayType,
        rowId,
        size,
        label,
        view,
        defaultValue,
        displayAsDetailRow,
        title,
        className,
        renderOnlyContent,
        classes,
        description,
        align,
        disableContextMenu,
        format,
        handleContextMenuFn,
        href,
    } = props;

    const p = useP();
    const can = useCan();
    const userSettings = useSelector((state) => state.app.settings.data);

    const handleClick = (e) => {
        onClick(e, attribute.id);
    };

    const _classes = useStylesCellBuilder(classes);

    let cellElement;
    let contextLabel;
    let cellTitle;

    let _data =
        dataEntity && data && data.value
            ? {
                  ...data,
                  ...dataEntity[data.value],
              }
            : defaultValue && !displayAsDetailRow
            ? { ...dataEntity[defaultValue] }
            : data;

    if (_data) {
        // use color from attribute displayOptions
        if (!_data.color && color) {
            _data.color = typeof color === "function" ? color(_data) : color;
        }

        let displayValue;

        if (composedFields) {
            let values = [];
            Object.keys(data).forEach((key) => {
                values.push(data[key].value);
            });
            displayValue = values.join(", ");
        }

        // use smallLabel on view [S]
        if (view === VIEWS.SMALL && _data.smallLabel) {
            _data.label = _data.smallLabel;
        }

        if (_data.value === GDPR_ENCRYPT_VALUE) {
            cellElement = _data.value;
        } else {
            if (_data.label) {
                contextLabel = _data.label;
            } else if (typeof label === "function" && p) {
                contextLabel = label(_data.value, p);
            } else {
                contextLabel = _data.value;
            }
            cellTitle = title && p && title(_data, p);
            switch (displayType) {
                case DISPLAY_ADDRESS:
                    cellElement = (
                        <CellAddress
                            name={_data.name}
                            streetAddress={_data.value}
                            city={_data.city}
                            postalCode={_data.postalCode}
                            country={_data.country}
                            isoCountry={_data.isoCountry}
                            view={view}
                            title={cellTitle}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_DATE:
                    contextLabel = moment(_data.value)
                        .tz(userSettings.timezone.name)
                        .format(format || "D MMMM YYYY");
                    cellElement = (
                        <CellDate
                            date={_data.value}
                            displayTime={displayTime}
                            formatShort={_data.dateFormatShort || userSettings.dateFormatShort}
                            view={view}
                            title={cellTitle}
                            className={className}
                            format={format}
                        />
                    );
                    break;

                case DISPLAY_MONTH:
                    const monthFormat = "MMMM YYYY";
                    contextLabel = moment(_data.value, "MM-yyyy")
                        .tz(userSettings.timezone.name)
                        .format(monthFormat);
                    cellElement = (
                        <CellMonth
                            date={_data.value}
                            view={view}
                            title={cellTitle}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_ICON:
                    cellElement = (
                        <CellIconStyled
                            label={_data.label}
                            icon={_data.icon}
                            color={_data.color}
                            view={view}
                            style={_data.style}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_IMAGE:
                    cellElement = (
                        <CellImageStyled
                            label={_data.label}
                            path={_data.img}
                            shortLabel={_data.shortLabel}
                            size={size}
                            view={view}
                            fallbackImage={_data.fallbackImage}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_AVATAR:
                    cellElement = (
                        <CellAvatar
                            label={_data.label}
                            value={_data.value}
                            src={_data.img}
                            isDisabled={_data.isDisabled}
                            isOnline={_data.isOnline}
                            isInternal={_data.internal}
                            view={view}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_NUMERIC:
                case DISPLAY_AMOUNT:
                    contextLabel = toUserLocalNumber(
                        Number(_data.value),
                        userSettings.language,
                        _data.precision
                    );
                    cellElement = (
                        <CellNumeric
                            value={Number(_data.value)}
                            currency={displayCurrency ? _data.currency : null}
                            precision={_data.precision}
                            locale={userSettings.language}
                            view={view}
                            title={cellTitle}
                            className={className}
                            align={align}
                        />
                    );
                    break;

                case DISPLAY_CHIP:
                    cellElement = (
                        <CellChip
                            value={_data.value}
                            color={_data.color}
                            align={_data.align}
                            style={_data.style}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_RATE:
                    contextLabel = formatRate(_data.value, view, userSettings.language);
                    cellElement = (
                        <CellRate
                            value={_data.value}
                            isPositive={_data.isPositive}
                            trendchip={_data.trendchip}
                            indicator={_data.indicator}
                            locale={userSettings.language}
                            view={view}
                            title={cellTitle}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_SENTINEL:
                    cellElement = (
                        <CellSentinel
                            active={_data.active}
                            color={_data.color}
                            label={_data.label}
                            letter={_data.label2}
                            outlined={_data.outlined}
                            value={_data.value}
                            reviewer={_data.reviewer}
                            className={className}
                            view={view}
                        />
                    );
                    break;

                case DISPLAY_3DS:
                    cellElement = (
                        <Cell3ds
                            label={_data.label}
                            letter={_data.label2}
                            color={_data.color}
                            active={_data.active}
                            value={displayValue ? displayValue : _data.value}
                            view={view}
                            title={cellTitle}
                            className={className}
                            onClick={onClick ? onClick(rowId, _data) : null}
                            href={href}
                            description={description}
                        />
                    );
                    break;

                case DISPLAY_SCORE:
                    cellElement = (
                        <CellScore
                            value={_data.value}
                            active={_data.active}
                            color={_data.color}
                            className={className}
                        />
                    );
                    break;
                case DISPLAY_CUSTOM_DATA:
                    if (_data.isBtnMore) {
                        cellElement = (
                            <CellButton
                                label={"common.moreButton"}
                                icon={"add_circle"}
                                color={"primary"}
                                view={view}
                                sizeIcon={22}
                                onClick={handleClick}
                                className={className}
                            />
                        );
                    } else {
                        if (/^([1-9]|10)\s{1}:/.test(_data.value)) {
                            contextLabel = _data.value;
                            _data.column = "old_custom_data";
                        }
                        cellElement = (
                            <CellTextStyled
                                value={_data.value}
                                title={_data.value}
                                view={"s"}
                                className={className}
                            />
                        );
                    }
                    break;

                case DISPLAY_LINK:
                    cellElement = (
                        <CellLink
                            label={_data.label}
                            value={_data.value}
                            onClick={_data.onClick}
                            view={view}
                            {...(title ? { title: title(_data, p) } : {})}
                        />
                    );
                    break;

                case DISPLAY_MULTIPLE_LINKS:
                    cellElement = <CellMultipleLinks value={_data.value} />;
                    break;

                case DISPLAY_BOOLEAN:
                    // Add context translation for LookUp context menu item
                    if (
                        typeof _data.value !== "undefined" &&
                        (!_data.value || _data.value === "0")
                    ) {
                        contextLabel = p.t("common.search.table.cell.boolean.no");
                    } else if (typeof _data.value !== "undefined") {
                        contextLabel = p.t("common.search.table.cell.boolean.yes");
                    }
                    cellElement = (
                        <CellBoolean
                            color={_data.color}
                            label={_data.label}
                            value={_data.value}
                            icon={_data.icon}
                            view={view}
                            style={_data.style}
                            className={_classes.booleanCell}
                            showLabel={_data.showLabel}
                        />
                    );
                    break;

                case DISPLAY_TEXT:
                case DISPLAY_LONG_TEXT:
                default:
                    cellElement = (
                        <CellTextStyled
                            label={_data.label}
                            color={_data.color}
                            active={_data.active}
                            value={displayValue ? displayValue : _data.value}
                            view={view}
                            title={cellTitle}
                            className={className}
                            onClick={onClick ? onClick(rowId, _data) : null}
                            href={href}
                            description={description}
                        />
                    );
                    break;

                case DISPLAY_STRINGIFY_OBJECT:
                    cellElement = <CellStringifyObject value={_data.value} />;
                    break;
            }
        }
    } else {
        cellElement = <CellEmpty />;
    }

    const contextMenuEnabled =
        !disableContextMenu &&
        (!attribute.rgpdCompliance || can("read", "gdpr")) &&
        !!_data &&
        _data.value !== GDPR_ENCRYPT_VALUE;

    let contextData;
    if (composedFields) {
        contextData = {};
        Object.keys(data).map(
            (key) =>
                (contextData = {
                    ...contextData,
                    [key]: {
                        label: data[key].value,
                        value: data[key].value,
                    },
                })
        );
    } else {
        contextData = {
            [attribute.id]: {
                label: contextLabel,
                value: _data.value,
            },
        };
    }

    const handleContextMenu = useNoticeContextMenu(contextMenuEnabled && contextData);

    if (renderOnlyContent) {
        return <div onContextMenu={handleContextMenuFn || handleContextMenu}>{cellElement}</div>;
    }

    return (
        <TableCell classes={classes} onContextMenu={handleContextMenuFn || handleContextMenu}>
            {cellElement}
        </TableCell>
    );
}

CellBuilder.propTypes = {
    /**
     * ClassName to override cell content style.
     */
    className: PropTypes.string,
    /**
     * Data to render the cell properly
     *  - label
     *  - value
     *  - ...
     */
    data: PropTypes.object,
    /**
     * List of entities available for this specific column with id as key.
     * ex : List of all card_categories.
     */
    dataEntity: PropTypes.object,
    /**
     * If true, cell does not have any contextMenu.
     */
    disableContextMenu: PropTypes.bool,
    /**
     * For CellAmount only. Displays currency if true.
     */
    displayCurrency: PropTypes.bool,
    /**
     * For cellDate only. Displays hours if true.
     */
    displayTime: PropTypes.bool,
    /**
     * Type of cell.
     */
    displayType: PropTypes.string,
    /**
     * Callback to override ContextMenu
     */
    handleContextMenuFn: PropTypes.func,
    /**
     * Callback when user clicks on cell.
     */
    onClick: PropTypes.func,
    /**
     * If true, renders only cell content without a <td> wrapper.
     */
    renderOnlyContent: PropTypes.bool,
    /**
     * Row unique Identifier.
     * Used in context menu for redirect to notice.
     */
    rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Is the cell sticky when user scrolls horizontally.
     */
    sticky: PropTypes.bool,
    /**
     * View (L/M/S)
     */
    view: PropTypes.oneOf(["l", "m", "s"]),
    /*
     * If true, renders dangerous HTML
     */
    description: PropTypes.bool,
};

CellBuilder.defaultProps = {
    disableContextMenu: false,
    displayCurrency: false,
    displayTime: false,
    renderOnlyContent: true,
    sticky: true,
    view: "l",
};

export default CellBuilder;
