import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import TableCell from "@mui/material/TableCell";
import { useP } from "../../../services/i18n";
import {
    DISPLAY_ADDRESS,
    DISPLAY_AMOUNT,
    DISPLAY_DATE,
    DISPLAY_ICON,
    DISPLAY_IMAGE,
    DISPLAY_NUMERIC,
    DISPLAY_CHIP,
    DISPLAY_RATE,
    DISPLAY_SENTINEL,
    DISPLAY_SCORE,
    DISPLAY_TEXT,
    DISPLAY_LONG_TEXT,
    DISPLAY_CUSTOM_DATA,
    DISPLAY_AVATAR,
    DISPLAY_LINK,
    DISPLAY_MONTH,
    DISPLAY_BOOLEAN,
    DISPLAY_MULTIPLE_LINKS,
    DISPLAY_3DS,
} from "../../constants/displayTypes";
import { VIEWS } from "../../../constants";
import { formatRate } from "@hipay/hipay-material-ui/utils/helpers";
import {
    CellAddress,
    CellEmpty,
    CellIconStyled,
    CellImageStyled,
    CellNumeric,
    CellRate,
    CellSentinel,
    CellScore,
    CellTextStyled,
    CellAvatar,
    CellButton,
    CellLink,
    CellMonth,
    CellBoolean,
    CellChip,
    CellMultipleLinks,
    CellDate,
    Cell3ds,
} from "./Cell";
import { toUserLocalNumber } from "../../../utils/i18n";
import { useSearchContextMenu } from "../../../services/ContextMenu";

export const CellBuilderSearch = (props) => {
    const {
        onClick,
        colId,
        color,
        data,
        dataEntity,
        disableContextMenu,
        displayCurrency,
        displayTime,
        displayType,
        withFormating,
        rowId,
        size,
        label,
        view,
        defaultValue,
        displayAsDetailRow,
        title,
        format,
        className,
        renderOnlyContent,
        classes,
        align,
    } = props;

    const p = useP();
    const userSettings = useSelector((state) => state.app.settings.data);

    let cellElement;
    let contextLabel;
    let copyLabel;
    let cellTitle;
    let cellFormat;
    let _data =
        dataEntity && data && dataEntity.hasOwnProperty(data.value)
            ? {
                  ...data,
                  ...dataEntity[data.value],
              }
            : defaultValue && !displayAsDetailRow
            ? { ...dataEntity[defaultValue] }
            : data;

    if (_data) {
        // use color from attribute displayOptions
        if (!_data.color && color) {
            _data.color = typeof color === "function" ? color(_data) : color;
        }

        // use smallLabel on view [S]
        if (view === VIEWS.SMALL && _data.smallLabel) {
            _data.label = _data.smallLabel;
        }

        if (_data.value === "******") {
            cellElement = _data.value;
        } else {
            if (_data.label) {
                contextLabel = _data.label;
            } else if (typeof label === "function" && p) {
                contextLabel = label(_data.value, p);
                _data.label = label(_data.value, p);
            } else {
                contextLabel = _data.value;
            }
            cellTitle = title && p && title(_data, p);
            cellFormat = format && format(_data);
            switch (displayType) {
                case DISPLAY_ADDRESS:
                    cellElement = (
                        <CellAddress
                            name={_data.name}
                            streetAddress={_data.value}
                            city={_data.city}
                            postalCode={_data.postalCode}
                            country={_data.country}
                            isoCountry={_data.isoCountry}
                            view={view}
                            title={cellTitle}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_DATE:
                    contextLabel = moment(_data.value)
                        .tz(userSettings.timezone.name)
                        .format("D MMMM YYYY");
                    copyLabel = moment(_data.value).tz(userSettings.timezone.name).format("LLLL");
                    cellElement = (
                        <CellDate
                            date={_data.value}
                            displayTime={displayTime}
                            formatShort={_data.dateFormatShort || userSettings.dateFormatShort}
                            view={view}
                            title={cellTitle}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_MONTH:
                    const monthFormat = "MMMM YYYY";
                    contextLabel = moment(_data.value, "MM-yyyy")
                        .tz(userSettings.timezone.name)
                        .format(monthFormat);
                    copyLabel = moment(_data.value, "MM-yyyy")
                        .tz(userSettings.timezone.name)
                        .format(monthFormat);
                    cellElement = (
                        <CellMonth
                            date={_data.value}
                            view={view}
                            title={cellTitle}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_ICON:
                    cellElement = (
                        <CellIconStyled
                            label={_data.label}
                            icon={_data.icon}
                            color={_data.color}
                            view={view}
                            style={_data.style}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_IMAGE:
                    cellElement = (
                        <CellImageStyled
                            label={_data.label}
                            path={_data.img}
                            shortLabel={_data.shortLabel}
                            size={size}
                            view={view}
                            fallbackImage={_data.fallbackImage}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_AVATAR:
                    cellElement = (
                        <CellAvatar
                            firstname={_data.firstname}
                            surname={_data.surname}
                            label={_data.label}
                            value={_data.value}
                            src={_data.img}
                            isDisabled={_data.isDisabled}
                            isOnline={_data.isOnline}
                            isInternal={_data.internal}
                            view={view}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_NUMERIC:
                case DISPLAY_AMOUNT:
                    contextLabel = toUserLocalNumber(
                        Number(_data.value),
                        userSettings.language,
                        _data.precision
                    );
                    copyLabel = _data.value;
                    cellElement = (
                        <CellNumeric
                            value={Number(_data.value)}
                            currency={_data.currency}
                            precision={_data.precision}
                            locale={userSettings.language}
                            view={view}
                            title={cellTitle}
                            className={className}
                            displayCurrency={displayCurrency}
                            displayType={displayType}
                        />
                    );
                    break;

                case DISPLAY_CHIP:
                    cellElement = (
                        <CellChip
                            value={_data.value}
                            color={_data.color}
                            align={align}
                            style={_data.style}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_RATE:
                    contextLabel = formatRate(_data.value, view, userSettings.language);
                    cellElement = (
                        <CellRate
                            value={_data.value}
                            isPositive={_data.isPositive}
                            trendchip={_data.trendchip}
                            indicator={_data.indicator}
                            locale={userSettings.language}
                            view={view}
                            title={cellTitle}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_SENTINEL:
                    cellElement = (
                        <CellSentinel
                            active={_data.active}
                            color={_data.color}
                            label={_data.label}
                            letter={_data.label2}
                            outlined={_data.outlined}
                            value={_data.value}
                            reviewer={_data.reviewer}
                            className={className}
                            view={view}
                        />
                    );
                    break;

                case DISPLAY_SCORE:
                    cellElement = (
                        <CellScore
                            value={_data.value}
                            active={_data.active}
                            color={_data.color}
                            className={className}
                        />
                    );
                    break;
                case DISPLAY_CUSTOM_DATA:
                    if (_data.isBtnMore) {
                        cellElement = (
                            <CellButton
                                label={"common.moreButton"}
                                icon={"add_circle"}
                                color={"primary"}
                                view={view}
                                sizeIcon={22}
                                onClick={onClick && onClick(rowId)}
                                className={className}
                            />
                        );
                    } else {
                        if (/^([1-9]|10)\s{1}:/.test(_data.value)) {
                            contextLabel = _data.value;
                            _data.column = "old_custom_data";
                        }
                        cellElement = (
                            <CellTextStyled
                                value={_data.value}
                                title={_data.value}
                                view={"s"}
                                className={className}
                            />
                        );
                    }
                    break;

                case DISPLAY_LINK:
                    cellElement = (
                        <CellLink
                            label={_data.label}
                            value={_data.value}
                            onClick={_data.onClick}
                            view={view}
                            {...(title ? { title: title(_data, p) } : {})}
                        />
                    );
                    break;

                case DISPLAY_MULTIPLE_LINKS:
                    cellElement = <CellMultipleLinks value={_data.value} />;
                    break;

                case DISPLAY_BOOLEAN:
                    // Add context translation for LookUp context menu item
                    if (
                        typeof _data.value !== "undefined" &&
                        (!_data.value || _data.value === "0")
                    ) {
                        contextLabel = p.t("common.search.table.cell.boolean.no");
                    } else if (typeof _data.value !== "undefined") {
                        contextLabel = p.t("common.search.table.cell.boolean.yes");
                    }
                    cellElement = (
                        <CellBoolean
                            color={_data.color}
                            value={_data.value}
                            icon={_data.icon}
                            view={view}
                            style={_data.style}
                            className={className}
                        />
                    );
                    break;

                case DISPLAY_3DS:
                    if (_data.label2) {
                        contextLabel = _data.label2;
                    }
                    cellElement = (
                        <Cell3ds
                            label={_data.label}
                            letter={_data.label2}
                            color={_data.color}
                            active={_data.active}
                            value={_data.value}
                            withFormating={withFormating}
                            view={view}
                            title={cellTitle}
                            cellFormat={cellFormat}
                            className={className}
                            onClick={onClick ? onClick(rowId, _data) : null}
                        />
                    );
                    break;

                case DISPLAY_TEXT:
                case DISPLAY_LONG_TEXT:
                default:
                    if (withFormating === false) {
                        copyLabel = JSON.stringify(cellFormat);
                    }
                    if (_data.label2) {
                        contextLabel = _data.label2;
                    }

                    cellElement = (
                        <CellTextStyled
                            label={_data.label}
                            color={_data.color}
                            active={_data.active}
                            value={_data.value}
                            withFormating={withFormating}
                            view={view}
                            title={cellTitle}
                            cellFormat={cellFormat}
                            className={className}
                            onClick={onClick ? onClick(rowId, _data) : null}
                        />
                    );
                    break;
            }
        }
    } else {
        cellElement = <CellEmpty />;
    }

    const handleContextMenu = useSearchContextMenu({
        [colId]: {
            label: contextLabel,
            value: _data ? _data.value : null,
            copyLabel,
        },
        rowId,
    });

    if (disableContextMenu || (_data && (_data.isBtnMore || _data.value === "******"))) {
        return renderOnlyContent ? (
            cellElement
        ) : (
            <TableCell classes={classes}>{cellElement}</TableCell>
        );
    }

    if (renderOnlyContent) {
        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                }}
                onContextMenu={handleContextMenu ? handleContextMenu : undefined}
            >
                {cellElement}
            </div>
        );
    }

    return (
        <TableCell
            classes={classes}
            onContextMenu={handleContextMenu ? handleContextMenu : undefined}
        >
            {cellElement}
        </TableCell>
    );
};

CellBuilderSearch.propTypes = {
    /**
     * ClassName to override cell content style.
     */
    className: PropTypes.string,
    /**
     * Classes to override cell wrapper style (<td>).
     */
    classes: PropTypes.object,
    /**
     * Id of the attribute corresponding to the cell
     */
    colId: PropTypes.string,
    /**
     * Data to render the cell properly
     *  - label
     *  - value
     *  - ...
     */
    data: PropTypes.object,
    /**
     * List of entities available for this specific column with id as key.
     * ex : List of all card_categories.
     */
    dataEntity: PropTypes.object,
    /**
     * If true, cell does not have any contextMenu.
     */
    disableContextMenu: PropTypes.bool,
    /**
     * For CellAmount only. Displays currency if true.
     */
    displayCurrency: PropTypes.bool,
    /**
     * For cellDate only. Displays hours if true.
     */
    displayTime: PropTypes.bool,
    /**
     * Type of cell.
     */
    displayType: PropTypes.string,
    /**
     * Callback when user clicks on cell.
     */
    onClick: PropTypes.func,
    /**
     * If true, renders only cell content without a <td> wrapper.
     */
    renderOnlyContent: PropTypes.bool,
    /**
     * Row unique Identifier.
     * Used in context menu for redirect to notice.
     */
    rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Is the cell sticky when user scrolls horizontally.
     */
    sticky: PropTypes.bool,
    /**
     * View (L/M/S)
     */
    view: PropTypes.oneOf(["l", "m", "s"]),
};

CellBuilderSearch.defaultProps = {
    disableContextMenu: false,
    displayCurrency: false,
    displayTime: false,
    renderOnlyContent: true,
    sticky: true,
    view: "l",
};
